import { Router } from 'vue-router';
import NProgress from '@/config/nprogress';
import PageUtil from '@/utils/pageUtil';
import { getToken } from '@/utils/auth';
import { useUserStoreWithOut } from '@/store/modules/user';

export function createApiDataGuard(router: Router) {
    const userStore = useUserStoreWithOut();
    /**
   * @description 路由拦截 beforeEach
   * */
  router.beforeEach(async (to, from, next) => {
    // 1.NProgress 开始
    NProgress.start();

    // 2.动态设置标题
    document.title = PageUtil.getPageTitle(String(to.meta.title));
    // 3.判断是访问登陆页，有 Token 就在当前页面，没有 Token 重置路由到登陆页
    if (getToken()) {
      // 正常访问页面
      await userStore.fetchUserInfo();
    }
    next();
  });

  /**
   * @description 路由跳转错误
   * */
  router.onError((error) => {
    NProgress.done();
    console.warn('路由错误', error.message);
  });

  /**
   * @description 路由跳转结束
   * */
  router.afterEach(() => {
    NProgress.done();
  });
}

/**
 * @description 重置路由
 * */
export const resetRouter = (router: Router) => {};
