/**
 * 页面操作工具类
 */
import { DictData } from '@/api/dict/type';
import { ElMessage, ElMessageBox, ElNotification } from 'element-plus';
import { RouteLocationNormalizedLoaded } from 'vue-router';
import moment from 'moment';
import I18n from '@/i18n'; //国际化

const t = I18n.global.t;
export default class PageUtil {
  // 获取页面的title
  static getPageTitle(pageTitle: string): string {
    const defaultTitle = 'EzCMMI';
    if (pageTitle) {
      return `${pageTitle} - ${defaultTitle}`;
    }
    return `${defaultTitle}`;
  }

  static msgSuccess(msg: string): void {
    PageUtil.msg(msg, 'success');
  }

  static msgInfo(msg: string): void {
    PageUtil.msg(msg, 'info');
  }

  static msgWarning(msg: string): void {
    PageUtil.msg(msg, 'warning');
  }

  static msgError(msg: string, defaultMsg = ''): void {
    if (msg && msg.length !== 0) {
      PageUtil.msg(msg, 'error');
    } else if (defaultMsg !== '') {
      PageUtil.msg(defaultMsg, 'error');
    }
  }

  static msg(msg: string, msgType: 'success' | 'info' | 'warning' | 'error', duration = 3000): void {
    ElMessage({
      message: msg,
      type: msgType,
      duration: duration,
      showClose: true,
    });
  }

  static notification(msg: string, nTitle = '', nType: any = 'success', position: any = 'top-right'): void {
    ElNotification({
      title: nTitle,
      message: msg,
      type: nType,
      position: position,
    });
  }

  // 判断路由名字是否和route匹配
  static routeMath(route: RouteLocationNormalizedLoaded, routeName: string, id: any = false): boolean {
    if (route.params.id) {
      if (route.params.id == id) {
        return true;
      }
      return false;
    } else {
      for (let i = 0; i < route.matched.length; i += 1) {
        if (route.matched[i].name === routeName) {
          return true;
        }
      }
      return false;
    }
  }

  // 判断路由名字是否在路里面
  static routeNameInPath(routePath: string, routeName: string): boolean {
    return routePath.toLowerCase().includes(routeName.toLowerCase());
  }
  // 根据字典值，获取字典标签
  static selectDictLabel(dictList: DictData[], value: string | number) {
    const actions: string[] = [];
    if (!!!dictList || dictList.length === undefined || dictList.length === 0) return '';

    if (Object.keys(dictList[0]).includes('value') && Object.keys(dictList[0]).includes('label')) {
      Object.keys(dictList).map((key) => {
        if (dictList[key].value === `${value}`) {
          actions.push(dictList[key].label);
        }
      });
    } else {
      Object.keys(dictList).map((key) => {
        if (dictList[key].dictValue === `${value}`) {
          actions.push(dictList[key].dictLabel);
        }
      });
    }
    return actions.join('');
  }
  static selectDictName(dictList: any[], value: string | number) {
    const actions: string[] = [];
    Object.keys(dictList).map((key) => {
      if (dictList[key].id == `${value}`) {
        actions.push(dictList[key].fieldName);
      }
    });
    return actions.join('');
  }

  static userNickName(memberList: any[], userId: string | number) {
    const actions: string[] = [];
    Object.keys(memberList).map((key) => {
      if (memberList[key].userId == `${userId}`) {
        actions.push(memberList[key].nickName);
      }
    });
    return actions.join('');
  }

  static async confirm(
    desc: string,
    title = t('utils.pageUtil.warning'),
    type: any = 'warning',
    closeOnClickModal: any = true,
  ) {
    let ret = false;
    await ElMessageBox.confirm(desc, title, {
      confirmButtonText: t('utils.pageUtil.sure'),
      cancelButtonText: t('utils.pageUtil.cancel'),
      type: type,
      closeOnClickModal,
    })
      .then(() => {
        ret = true;
      })
      .catch(() => {
        ret = false;
      });

    return ret;
  }

  /**
   * 清空数组
   * @param dataList 数组对象
   */
  static resetArray<T>(dataList: T[]) {
    if (dataList.length > 0) {
      for (let i = dataList.length - 1; i >= 0; i -= 1) {
        dataList.splice(i, 1);
      }
    }
  }

  /**
   * 复制数组
   * @param sourceList 源数组
   * @param targetList 目标数组
   */
  static copyArray<T>(targetList: T[], sourceList: T[]) {
    PageUtil.resetArray(targetList);
    if (sourceList !== undefined && sourceList.length > 0) {
      targetList.push(...sourceList);
    }
  }

  /**
   * 清空对象
   * @param obj {x:xx, y:yy}
   */
  static resetObject<T>(obj: T) {
    Object.keys(obj).map((key) => {
      delete obj[key];
    });
  }

  /**
   * 复制对象
   * @param source 源对象
   * @param target 目标对象
   */
  static copyObject<T>(target: T, source: T) {
    PageUtil.resetObject(target);
    if (source !== undefined) {
      Object.assign(target, source);
    }
  }

  /**
   * 格式化时间
   * @param t 时间字符串
   * @param pattern 格式
   * @returns 格式化后的时间
   */
  static timeFormat(t: string, pattern = 'yyyy-MM-DD HH:mm') {
    return moment(t).format(pattern);
  }

  /**
   * 后台金额分转元，保留两位小数
   * @param num 时间字符串
   * @returns 格式化后的时间
   */
  static money(num: number) {
    return (num / 100).toFixed(2);
  }
  // 去除乘法小数精度
  static accMul = (arg1, arg2) => {
    let m = 0;
    const s1 = arg1.toString();
    const s2 = arg2.toString();
    try {
      m += s1.split('.')[1].length;
    } catch (e) {}
    try {
      m += s2.split('.')[1].length;
    } catch (e) {}
    return (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) / Math.pow(10, m);
  };
  // 去除除法小数精度
  static accDiv(arg1, arg2) {
    let t1 = 0;
    let t2 = 0;
    let r1, r2;
    try {
      t1 = arg1.toString().split('.')[1].length;
    } catch (e) {}
    try {
      t2 = arg2.toString().split('.')[1].length;
    } catch (e) {}
    r1 = Number(arg1.toString().replace('.', ''));
    r2 = Number(arg2.toString().replace('.', ''));
    const intDiv = r1 / r2;
    const pow = Math.pow(10, t2 - t1);
    return PageUtil.accMul(intDiv, pow);
  }
  static randomWord(randomFlag, min: any = false, max: any = false) {
    let str = '',
      range = min,
      arr = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        'a',
        'b',
        'c',
        'd',
        'e',
        'f',
        'g',
        'h',
        'i',
        'j',
        'k',
        'l',
        'm',
        'n',
        'o',
        'p',
        'q',
        'r',
        's',
        't',
        'u',
        'v',
        'w',
        'x',
        'y',
        'z',
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
      ];
    let pos;
    // 随机产生
    if (randomFlag) {
      range = Math.round(Math.random() * (max - min)) + min; // 任意长度
    }
    for (let i = 0; i < range; i++) {
      pos = Math.round(Math.random() * (arr.length - 1));
      str += arr[pos];
    }
    return str;
  }

  /**
   * 获取指定路由信息
   * @param routerList 路由列表（获取路由列表：router.options.routes）
   * @param routerName 查找路由的 name
   */
  static getRouter = (routerList, routerName) => {
    for (const r of routerList || []) {
      if (r.name === routerName) return r;
      const r_ = PageUtil.getRouter(r.children, routerName);
      if (r_) return r_;
    }
  };
  /**
   *
   * @param originalArray 判断是否是有效值
   * @param data 要判断的数据
   */
  //
  static isDataValid(data) {
    if (data === null || data === undefined) {
      return false; // 数据为null或undefined
    }

    if (typeof data === 'object' && Object.keys(data).length === 0 && data.constructor === Object) {
      return false; // 对象为空
    }

    if (Array.isArray(data) && data.length === 0) {
      return false; // 数组为空
    }

    if (typeof data === 'string' && data.trim() === '') {
      return false; // 字符串为空
    }

    return true; // 数据包含有效值
  }
}

// 转换/n
export const transformN = (str) => {
  return str.replaceAll('\n', '<br/>');
};
