<!--
-@Created by nkq
-@Created at 2024/02/02 14:32
-@Modify By
-@Modify at
-@description: 网站头部导航
-->
<template>
  <div class="header-block shadow-sm">
    <!-- 网站左侧logo区域 start -->
    <div class="logo-block" @click.prevent="goToBg">
      <h1><a href="/" @click.prevent>EZCMMI</a></h1>
      <div class="title">EZCMMI</div>
    </div>
    <!-- 网站左侧logo区域 end -->

    <!-- 网站中间导航区域 start -->
    <div class="nav-block" v-if="!chouseRouteMath('bg') && !chouseRouteMath('setting')">
      <ul class="nav-content flex-fill">
        <template v-for="(item, index) in headerMenuList" :key="index">
          <li
            :class="['nav-item cursor-pointer', { 'nav-active-item': checkRouteMath(item.name) }]"
            @click="goNavPage(item.name)"
            ><a :href="item.path" @click.prevent>{{ item.title }}</a></li
          >
        </template>
      </ul>
    </div>
    <!-- 网站中间导航区域 end -->

    <!-- 网站右侧头像区域 start -->
    <div class="avatar-block">
      <!-- 消息 -->
      <div v-if="isLogin" class="mr-3">
        <svg-icon :size="24" class="mr-2 cursor-pointer" name="message" />
      </div>

      <!-- 国际化 -->
      <div class="mr-3">
        <InternationalLanguage />
      </div>

      <el-tooltip v-if="userId == '1'" content="系统设置" placement="bottom" :show-after="400" class="el-tooltip">
        <a :href="'setting'" @click.prevent>
          <svg-icon
            :size="24"
            class="mr-2 cursor-pointer"
            :name="`setting${checkRouteMath('Setting') ? '-blue' : '-black'}`"
            @click="btnCommand('Setting')"
          />
        </a>
      </el-tooltip>
      <div v-if="isLogin" class="d-flex-start">
        <PersonalHomepage />
      </div>

      <div v-else>
        <el-button text @click="goLogin">登录</el-button>
        <el-button type="primary" @click="goRegister">注册</el-button>
      </div>
    </div>

    <!-- 网站右侧头像区域 end -->
  </div>
</template>

<script setup lang="ts">
  import { watch, reactive, computed, ref } from 'vue';
  import PageUtil from '@/utils/pageUtil';
  import { useRoute, useRouter } from 'vue-router';
  import { useUserStoreWithOut } from '@/store/modules/user';
  import InternationalLanguage from '@/components/InternationalLanguage/index.vue';
  import PersonalHomepage from '@/components/PersonalHomepage/index.vue';

  const route = useRoute();
  const router = useRouter();
  const userStore = useUserStoreWithOut();

  const isLogin = computed(() => !!userStore.getAccessToken); //是否登录
  const userId = computed(() => userStore.getUserInfo?.userId); //用户id
  const headerMenuList = reactive([
    { name: 'home', path: '/', title: '首页' },
    { name: 'project', path: '/', title: '找项目' },
    { name: 'appraiser', path: '/', title: '找评估师' },
    { name: 'translator', path: '/', title: '找翻译' },
    { name: 'trends', path: '/', title: 'CMMI动态' },
    { name: 'community', path: '/', title: 'CMMI社区' },
  ]);

  // 判断路由名字是否在路里面
  const chouseRouteMath = (routeName: string): boolean => {
    return PageUtil.routeNameInPath(route.path, routeName);
  };
  // 检查菜单是否激活
  const checkRouteMath = (routeName: string): boolean => {
    return PageUtil.routeMath(route, routeName);
  };

  // 点击导航
  const goNavPage = async (routeName: string) => {
    router.push({ name: routeName });
  };

  // 导航右侧按钮点击方法
  const btnCommand = (name) => {
    window.localStorage.removeItem('scrollDashboard');
    if (checkRouteMath(name)) return;
    //判断是否含有权限
    if ('') return PageUtil.msgWarning('没有访问权限,请联系管理员!');
    if (name === 'Setting') {
      const to = router.resolve({
        name: 'Setting', //这里是跳转页面的name，要与路由设置保持一致
      });
      window.open(to.href, '_blank');
    }
  };
  // 去登录
  const goLogin = () => {
    userStore.setLoginVisible(true);
    userStore.setIsRegister(false);
  };
  // 去注册
  const goRegister = () => {
    userStore.setLoginVisible(true);
    userStore.setIsRegister(true);
  };

  // 定义导航到后台页面的方法
  const goToBg = () => {
    router.push({ name: 'home' });
  };
</script>

<style scoped lang="scss">
  $header-height: 64px;

  .header-block {
    width: 100%;
    height: $header-height;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;

    .logo-block {
      padding-left: 24px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      a {
        display: block;
        width: 32px;
        height: 32px;
        background: url('@/assets/images/logo-circle-fill.png') no-repeat;
        font-size: 0;
        text-indent: -9999px;
        background-size: cover;
      }

      .title {
        margin-left: 8px;
        font-size: 22px;
        font-weight: 600;
      }
    }

    .nav-block {
      margin-left: 198px;
      .nav-content {
        height: $header-height;
        flex: auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        font-weight: bold;
        .nav-item {
          height: $header-height;
          line-height: $header-height;
          padding: 0;
          border-bottom: 2px solid;
          border-color: transparent;
          white-space: nowrap;
          margin-right: 50px;
          a {
            display: block;
            height: $header-height;
          }
        }
        .nav-active-item {
          color: #0091ff;
          border-color: #0091ff;
        }
      }
    }

    .avatar-block {
      padding-right: 24px;
      display: flex;
      align-items: center;
    }
  }
</style>
