import Cookie from 'js-cookie';

const TOKEN_KEY = 'ez_cm_access';

export function setToken(token) {
  const expires = 11 * 60 * 60 * 1000; // 将小时转换为毫秒
  const options = { expires };
  Cookie.set(TOKEN_KEY, token, options);
  localStorage.setItem(TOKEN_KEY, token);
}

export function getToken() {
  const token = Cookie.get(TOKEN_KEY);
  return token;
}

export function removeToken() {
  localStorage.removeItem(TOKEN_KEY);
  return Cookie.remove(TOKEN_KEY);
}

export function isLogin() {
  return !!getToken();
}
