/**
 * @description: Request result set
 */

export enum ResultEnum {
  SUCCESS = 200,
  CREATED = 201,
  ACCEPTED = 202,
  NO_CONTENT = 204,
  MOVED_PERM = 301,
  SEE_OTHER = 303,
  NOT_MODIFIED = 304,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  REMOTE_LOGIN = 402,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  BAD_METHOD = 405,
  CONFLICT = 409,
  UNSUPPORTED_TYPE = 415,
  ERROR = 500,
  NOT_IMPLEMENTED = 501,

  TIMEOUT = 700,
}

/**
 * @description: request method
 */
export enum RequestEnum {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

/**
 * @description:  contentTyp
 */
export enum ContentTypeEnum {
  // json
  JSON = 'application/json;charset=UTF-8',
  // form-data qs
  FORM_URLENCODED = 'application/x-www-form-urlencoded;charset=UTF-8',
  // form-data  upload
  FORM_DATA = 'multipart/form-data;charset=UTF-8',
}
