import { createApp } from 'vue';
import './styles/index.scss';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import App from './App.vue';
import setupRouter, { router } from '@/router/index';
import { setupStore } from '@/store';
import setupRouterGuard from './router/guard';
import 'virtual:svg-icons-register';
import { setupGlobDirectives } from './directive';
import { registerComponents } from '@/components';
import I18n from '@/i18n'; //国际化
import '@/utils/pxrem';
import '@element-plus/icons-vue';
async function bootstrap() {
  const app = createApp(App);
  // 正式环境清除所有console.log
  if (import.meta.env.PROD) {
    if (window) {
      window.console.log = function () {};
    }
  }

  // 配置store
  setupStore(app);
  // 设置路由
  setupRouter(app);
  // 设置路由导航首位
  setupRouterGuard(router);
  // 设置全局指令
  setupGlobDirectives(app);
  // 注册全局组件
  registerComponents(app);

  app
    .use(ElementPlus) // 全局注册所有element plus组件
    .use(I18n)
    .mount('#app');
}

bootstrap();
