import { UserInfo } from '#/store';
import { defineStore } from 'pinia';
import { store } from '@/store';
import { getUserInfoDetailApi } from '@/api/system/index';
import { getToken } from '@/utils/auth';

interface UserState {
  userInfo: Nullable<UserInfo>;
  accessToken: string | undefined;
  loginVisible: boolean;
  isRegister: boolean;
}

export const useUserStore = defineStore('EzCmUser', {
  state: (): UserState => ({
    userInfo: null,
    accessToken: getToken(),
    loginVisible: false,
    isRegister: false,
  }),
  getters: {
    getUserInfo(): Nullable<UserInfo> {
      return this.userInfo;
    },
    getAccessToken(): string | undefined {
      return this.accessToken;
    },
    getLoginVisible(): boolean {
      return this.loginVisible;
    },
    getIsRegister(): boolean {
      return this.isRegister;
    },
  },
  actions: {
    // 获取个人信息
    async fetchUserInfo() {
      if (!this.getUserInfo?.userId && !!getToken()) {
        await getUserInfoDetailApi().then((res) => {
          res.data.isVisitor = true;
          this.setUserInfo(res.data);
        });
      }
    },
    setUserInfo(info: UserInfo | null) {
      this.userInfo = info || {};
    },
    setAccessToken(token: string) {
      this.accessToken = token;
    },
    setLoginVisible(loginVisible: boolean) {
      this.loginVisible = loginVisible;
    },
    setIsRegister(isRegister: boolean) {
      this.isRegister = isRegister;
    },
  },
  persist: {
    storage: window.sessionStorage,
  },
});

export function useUserStoreWithOut() {
  return useUserStore(store);
}
