export default {
  http: {
    warning: '警告',
    noData: '没有返回数据',
    timeOut: '请求超时',
    connectOut: '连接超时',
    stateOut: '登录状态已过期',
    networkError: '网络错误',
  },
  utils: {
    pageUtil: {
      warning: '警告',
      cancel: '取消',
      sure: '确认',
    },
  }
}