import { createI18n } from 'vue-i18n';
import elementEn from 'element-plus/dist/locale/en';
import elementZh from 'element-plus/dist/locale/zh-cn';
import en from './lang/en';
import zh from './lang/zh';

// const { crc32 } = require('crc');
const messages = {
  en: { ...en, ...elementEn },
  zh: { ...zh, ...elementZh },
};

const localLang = localStorage.getItem('locale'); // 业务需要存放仓库, 如element国际化, 需要刷新页面重新加载, 在main.js初始化element国际化语言. 这里根据你的业务去做语言切换功能.

// localStorage.setItem('locale', 'zh');
// @ts-ignore
const i18n = createI18n({
  locale: localLang || 'zh',
  fallbackLocale: localLang || 'zh', //默认中文语言
  messages,
  globalInjection: true,
  silentTranslationWarn: true, // 去掉此警告 [vue-i18n] Value of key ‘看板管理’ is not a string!
});

// --------这里是i18next-scanner新增的配置-------------
// export function lang(key) {
//   const hashKey = `K${crc32(key).toString(16)}`; // 将中文转换成crc32格式去匹配对应的json语言包
//   let words = i18n.t(hashKey);
//   if (words == hashKey) {
//     words = key;
//     console.log(key, '-无匹配语言key');
//   }
//   return words;
// }

export default i18n;
