import type { RouteLocationNormalized, RouteRecordNormalized } from 'vue-router';
import type { App, Plugin } from 'vue';

import { unref } from 'vue';
import { isObject } from '@/utils/is';
// import PageUtil from '@/utils/pageUtil';
// import I18n from '@/i18n'; //国际化
// const t = I18n.global.t;

export const noop = () => {};

/**
 * @description:  Set ui mount node
 */
export function getPopupContainer(node?: HTMLElement): HTMLElement {
  return (node?.parentNode as HTMLElement) ?? document.body;
}

/**
 * Add the object as a parameter to the URL
 * @param baseUrl url
 * @param obj
 * @returns {string}
 * eg:
 *  let obj = {a: '3', b: '4'}
 *  setObjToUrlParams('www.baidu.com', obj)
 *  ==>www.baidu.com?a=3&b=4
 */
export function setObjToUrlParams(baseUrl: string, obj: any): string {
  let parameters = '';
  for (const key in obj) {
    parameters += key + '=' + encodeURIComponent(obj[key]) + '&';
  }
  parameters = parameters.replace(/&$/, '');
  return /\?$/.test(baseUrl) ? baseUrl + parameters : baseUrl.replace(/\/?$/, '?') + parameters;
}

export function deepMerge<T = any>(src: any = {}, target: any = {}): T {
  let key: string;
  for (key in target) {
    src[key] = isObject(src[key]) ? deepMerge(src[key], target[key]) : (src[key] = target[key]);
  }
  return src;
}

export function deepCopy<T = any>(src: any = {}): T {
  return JSON.parse(JSON.stringify(src));
}

export function openWindow(
  url: string,
  opt?: { target?: TargetContext | string; noopener?: boolean; noreferrer?: boolean },
) {
  const { target = '__blank', noopener = true, noreferrer = true } = opt || {};
  const feature: string[] = [];

  noopener && feature.push('noopener=yes');
  noreferrer && feature.push('noreferrer=yes');

  window.open(url, target, feature.join(','));
}

// dynamic use hook props
export function getDynamicProps<T, U>(props: T): Partial<U> {
  const ret: Recordable = {};

  Object.keys(props).map((key) => {
    ret[key] = unref((props as Recordable)[key]);
  });

  return ret as Partial<U>;
}

export function getRawRoute(route: RouteLocationNormalized): RouteLocationNormalized {
  if (!route) return route;
  const { matched, ...opt } = route;
  return {
    ...opt,
    matched: (matched
      ? matched.map((item) => ({
          meta: item.meta,
          name: item.name,
          path: item.path,
        }))
      : undefined) as RouteRecordNormalized[],
  };
}

export const withInstall = <T>(component: T, alias?: string) => {
  const comp = component as any;
  comp.install = (app: App) => {
    app.component(comp.name || comp.displayName, component);
    if (alias) {
      app.config.globalProperties[alias] = component;
    }
  };
  return component as T & Plugin;
};

// 获取真实的domain，如果有www.则去掉
export function getRealDomain() {
  const domain = document.domain;
  if (domain.startsWith('www.')) {
    return domain.substring(4);
  } else if (domain.startsWith('project.')) {
    return domain.substring(8);
  } else {
    return domain;
  }
}
export function getOrigin() {
  const origin = location.origin;
  if (origin.startsWith('www.')) {
    return origin.substring(4);
  } else {
    return origin;
  }
}
/**
 * json字符串转为formData格式，方便post上传
 * @param params json字符串
 * @returns {FormData}
 */
export function json2FormData(params) {
  const formData = new FormData();
  Object.keys(params).forEach((key) => {
    if (params[key] !== undefined && params[key] !== '' && params[key] !== null) {
      formData.append(key, params[key]);
    }
  });
  return formData;
}

// 生成UUID
export function generateUUID() {
  let d = new Date().getTime();
  if (window.performance && typeof window.performance.now === 'function') {
    d += performance.now(); // use high-precision timer if available
  }
  return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}
// 将流数据，下载到指定文件中
export function download(fileName, data) {
  const blob = new Blob([data]); //type为所需要下载的文件格式，xls文件
  const link = document.createElement('a'); //创建a标签
  link.download = `${fileName}`; //a标签添加属性
  link.style.display = 'none';
  link.href = URL.createObjectURL(blob);
  document.body.appendChild(link);
  link.click(); //执行下载
  URL.revokeObjectURL(link.href); //释放url
  document.body.removeChild(link); //释放标签
  return true;

  // PageUtil.msgSuccess(t('requirement.exportSuc'));
}

// 获取assets静态资源  只适用于开发环境
export function getAssetsFile(url: string) {
  return new URL(`../assets/images${url}`, import.meta.url).href;
}

// 深拷贝
export const DeepClone = (value) => {
  let newValue;
  // 深克隆代码
  if (value instanceof Function) return value;
  else if (value instanceof Array) {
    newValue = [];
    for (let i = 0; i < value.length; ++i) newValue[i] = DeepClone(value[i]);
    return newValue;
  } else if (value instanceof Object) {
    newValue = {};
    for (const i in value) newValue[i] = DeepClone(value[i]);
    return newValue;
  } else return value;
};
//判断两个对象是否相等
export const isEqual = (objA, objB) => {
  //相等
  if (objA === objB) return objA !== 0 || 1 / objA === 1 / objB;
  //空判断
  if (objA == null || objB == null) return objA === objB;
  //类型判断
  if (Object.prototype.toString.call(objA) !== Object.prototype.toString.call(objB)) return false;

  switch (Object.prototype.toString.call(objA)) {
    case '[object RegExp]':
    case '[object String]':
      //字符串转换比较
      return '' + objA === '' + objB;
    case '[object Number]':
      //数字转换比较,判断是否为NaN
      if (+objA !== +objA) {
        return +objB !== +objB;
      }

      return +objA === 0 ? 1 / +objA === 1 / objB : +objA === +objB;
    case '[object Date]':
    case '[object Boolean]':
      return +objA === +objB;
    case '[object Array]':
      //判断数组
      for (let i = 0; i < objA.length; i++) {
        if (!isEqual(objA[i], objB[i])) return false;
      }
      return true;
    case '[object Object]':
      //判断对象
      let keys = Object.keys(objA);
      for (let i = 0; i < keys.length; i++) {
        if (!isEqual(objA[keys[i]], objB[keys[i]])) return false;
      }

      keys = Object.keys(objB);
      for (let i = 0; i < keys.length; i++) {
        if (!isEqual(objA[keys[i]], objB[keys[i]])) return false;
      }

      return true;
    default:
      return false;
  }
};
export const utilUploadFile = (file) => {
  const link = document.createElement('a'); //创建a标签
  link.setAttribute('target', '_blank');
  link.href = file.fileUrl;
  link.click(); //执行下载
};

/**
 * 判断手机号是否11位合法
 * @param phone
 * @returns {boolean}
 */
export const isPhoneAvailable = (phone) => {
  const phone_reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
  return phone_reg.test(phone);
};

/**
 * 判断邮箱是否合法
 * @param email
 * @returns {boolean}
 */
export const isEmailAvailable = (email) => {
  const email_reg = /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/;
  return email_reg.test(email);
};
