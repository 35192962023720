/**
 * @Created by why
 * @Created at 2024/02/21 18:22
 * @Modify By
 * @Modify at
 * @description 登录注册接口
 */

import { defHttp } from '@/http';
import { BaseModel } from '@/api/model/model';

/**
 * 登录
 * @param 
 * @returns
 */
export function loginApi(data) {
  return defHttp.post({
    url: '/login',
    data
  });
}
/**
 * 退出登录
 * @param 
 * @returns
 */
export function logoutApi() {
  return defHttp.post({
    url: '/logout',
  });
}
/**
 * 注册
 * @param data 
 * @returns 
 */
export function registerApi(data) {
  return defHttp.post({
    url: '/register',
    data
  });
}
/**
 * 发送验证码
 * @param data 
 * @returns 
 */
export function registerCodeApi(data) {
  return defHttp.post({
    url: '/register/code',
    data
  });
}
/**
 * 判断邮箱是否重复
 * @param data 
 * @returns 
 */
export function registerCheckApi(data) {
  return defHttp.post({
    url: '/register/check',
    data
  });
}
