<!--
-@Created by nkq
-@Created at 2024/01/29 17:12
-@Modify By 
-@Modify at 
-@description: svg图标组件
-->
<template>
  <svg :class="svgClass" v-bind="$attrs" :style="style">
    <use :xlink:href="symbolId" :fill="color" />
  </svg>
</template>

<script lang="ts">
  import { defineComponent, computed, reactive, toRefs } from 'vue';

  export default defineComponent({
    name: 'SvgIcon',
    props: {
      name: {
        type: String,
        required: true,
      },
      color: {
        type: String,
        default: '',
      },
      size: {
        type: Number,
        default: 20,
      },
      // 子文件目录
      subFolder: {
        type: String,
        default: '',
      },
    },
    setup(props) {
      const reactiveData = reactive({
        symbolId: computed(() => {
          let folder = props.subFolder ? `${props.subFolder}-` : '';
          return `#icon-${folder}${props.name}`;
        }),
        svgClass: computed(() => {
          return props.name ? `svg-icon icon-${props.name}` : `svg-icon`;
        }),
        style: computed(() => {
          return { color: props.color, width: `${props.size}px`, height: `${props.size}px` };
        }),
      });

      return {
        ...toRefs(reactiveData),
      };
    },
  });
</script>

<style scoped lang="scss">
  .svg-icon {
    width: 1em;
    height: 1em;
    fill: currentColor;
    vertical-align: middle;
  }
</style>
