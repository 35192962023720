/**
 * @Created by nkq
 * @Created at 2024/01/30 10:13
 * @Modify By
 * @Modify at
 * @description 注册全局组件
 */

import svgIcon from '@/components/SvgIcon/index.vue';
import avatar from '@/components/Avatar/index.vue';

export function registerComponents(app) {
  app.component('svg-icon', svgIcon);
  app.component('avatar', avatar);
}
