/**
 * @Created by lyj
 * @Created at 2021/12/23 14:30
 * @Modify By
 * @Modify at
 * @description: 加载状态自定义指令
 **/

import $ from 'jquery';
import { App, Directive } from 'vue';

function insertMask(el) {
  const block = $(el);
  block.css('position', 'relative');
  const blockWidth = block.width();

  const className = block.get(0).attributes[0].nodeName + '-mask';
  const mask = $('<div style="position:absolute;top: 0;left: 0;z-index: 999;background-color: #FFFFFF;"></div>');
  mask.css('width', blockWidth! - 0 + 'px');

  const height = block.get(0).offsetHeight < 100 ? 100 : block.height();
  mask.css('height', height + 'px');
  const loadingWrap = $(
    '<div style="position: absolute;top: 50%;left: 50%;transform: translateX(-50%);min-width: 140px;">' +
      '<div class="loader-inner line-scale" style="text-align: center;">' +
      '<div style="background-color: #90b5e1;"></div>' +
      '<div style="background-color: #9ee1a2;"></div>' +
      '<div style="background-color: #9179e1;"></div>' +
      '<div style="background-color: #e171dc;"></div>' +
      '<div style="background-color: #e1707e;"></div>' +
      '</div>' +
      '<div style="font-size: 14px;color: #7492b5;text-align: center;">加载中，请稍后</div>' +
      '</div>',
  );
  mask.addClass(className);
  mask.append(loadingWrap);

  block.append(mask);
}

const cocodeLoadingDirective: Directive = {
  // 加入，加载页面
  mounted(el) {
    insertMask(el);
  },

  updated(el, binding, _vnode) {
    // 重新加入加载页面
    if (binding.value === true) {
      insertMask(el);
    }

    // 移除，加载页面
    if (binding.value === false) {
      const block = $(el);
      const className = block[0].attributes[0].nodeName + '-mask';
      block.children(`.${className}`).remove();
    }
  },
};

export function setupCocodeLoadingDirective(app: App) {
  app.directive('cocodeLoading', cocodeLoadingDirective);
}

export default cocodeLoadingDirective;
