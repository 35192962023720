<template>
  <el-config-provider :locale="locale">
    <layout />
  </el-config-provider>
</template>

<script setup lang="ts">
  import { onMounted, ref } from 'vue';
  import { ElConfigProvider } from 'element-plus';
  import Layout from '@/layout/index.vue';
  import zhCn from 'element-plus/dist/locale/zh-cn';
  import En from 'element-plus/dist/locale/en';

  const locale = ref(zhCn);

  onMounted(() => {
    if (String(localStorage.getItem('locale')) === 'en') {
      locale.value = En;
    }
  });
</script>

<style lang="scss"></style>
