/**
 * 使用方法
 * <div v-mini-height>...</div>
 * 注意：和v-cocode-loading一起使用时，需要放在v-cocode-loading之前
 */
import $ from 'jquery';
import { App, Directive } from 'vue';

function setMiniHeight(el: any) {
  const block = $(el);
  block.css('box-sizing', 'border-box');
  const winHeight = $(window).innerHeight();
  // @ts-ignore
  const offsetTop = block.offset().top || 0;

  // @ts-ignore
  let height = winHeight - offsetTop - 7;
  if (height < 600) height = 600;

  block.css({ 'min-height': `${height}px` });
  block.css({ height: `1px` });
}

const miniHeightDirective: Directive = {
  mounted: function (el) {
    setMiniHeight(el);
  },
};

export function setupMiniHeightDirective(app: App) {
  app.directive('miniHeight', miniHeightDirective);
}

export default miniHeightDirective;
