<!--
-@Created by nkq
-@Created at 2024/02/19 15:17
-@Modify By
-@Modify at
-@description: 注册登录弹框
-->

<template>
  <el-dialog v-model="isVisible" width="516" :close-on-click-modal="false" @close="closeDialog">
    <div v-if="isLogin" class="block">
      <div class="title">登录</div>
      <div class="d-flex-end mb-2">
        <el-button type="primary" link @click="loginForm.isCode = !loginForm.isCode">{{
          loginForm.isCode ? '密码登录' : '验证码登录'
        }}</el-button>
      </div>
      <el-form :model="loginForm" label-width="80px" :rules="rules" ref="loginFormRef">
        <el-form-item label="电子邮件" prop="username">
          <el-input v-model="loginForm.username" placeholder="请输入电子邮件" @keyup.enter="login" />
        </el-form-item>
        <el-form-item v-if="!loginForm.isCode" label="密码" prop="password">
          <el-input
            v-model="loginForm.password"
            placeholder="请输入密码"
            type="password"
            show-password
            @keyup.enter="login"
          />
        </el-form-item>
        <el-form-item v-else label="验证码" prop="code">
          <div class="d-flex-between">
            <el-input
              v-model="loginForm.code"
              placeholder="请输入验证码"
              class="code"
              maxlength="6"
              @keyup.enter="login"
            />
            <el-button plain class="ml-3" @click="getCode">{{ codeBtnText }}</el-button>
          </div>
        </el-form-item>
      </el-form>
      <div class="d-flex-end">
        <el-button type="primary" link>忘记密码?</el-button>
      </div>

      <div class="d-flex-center">
        <el-checkbox v-model="loginForm.agree" label="我已阅读并同意" size="large" @keyup.enter="login" />
        <el-button type="primary" link>服务协议、隐私政策</el-button>
      </div>
      <div class="d-flex-center">
        <el-button class="btn" type="primary" :disabled="!loginForm.agree" @click="login">登录</el-button>
      </div>

      <div class="d-flex-center">
        <span>没有账号？</span><el-button type="primary" link @click="isLogin = false">注册</el-button>
      </div>
    </div>

    <div v-else class="block">
      <div class="title">注册</div>
      <el-form :model="registerForm" label-width="80px" :rules="rules" ref="registerFormRef">
        <el-form-item label="电子邮件" prop="email">
          <el-input v-model="registerForm.email" placeholder="请输入电子邮件" @keyup.enter="register" />
        </el-form-item>
        <el-form-item label="验证码" prop="code">
          <el-row>
            <el-col :span="17">
              <el-input
                v-model="registerForm.code"
                placeholder="请输入验证码"
                class="code"
                maxlength="6"
                @keyup.enter="register"
              />
            </el-col>
            <el-col :span="6">
              <el-button plain class="ml-3" @click="getCode">{{ codeBtnText }}</el-button>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="registerForm.password" placeholder="请输入密码" type="password" show-password />
        </el-form-item>
        <el-form-item label="确认密码" prop="surePassword">
          <el-input
            v-model="registerForm.surePassword"
            placeholder="请输再次入密码"
            type="password"
            show-password
            @keyup.enter="register"
          />
        </el-form-item>
        <el-form-item label="我是" prop=" userRole">
          <el-radio-group v-model="registerForm.userRole">
            <el-radio label="company">企业</el-radio>
            <el-radio label="assessor">评估师</el-radio>
            <el-radio label="translator">翻译</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="d-flex-center">
        <el-checkbox v-model="registerForm.agree" label="我已阅读并同意" size="large" @keyup.enter="register" />
        <el-button type="primary" link>服务协议、隐私政策</el-button>
      </div>
      <div class="d-flex-center">
        <el-button class="btn" type="primary" :disabled="!registerForm.agree" @click="register" @keyup.enter="register"
          >注册</el-button
        >
      </div>
      <div class="d-flex-center">
        <span>已有账号？</span><el-button type="primary" link @click="isLogin = true">立即登录</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script setup lang="ts">
  import { ref, computed, reactive, watch } from 'vue';
  import { setToken } from '@/utils/auth';
  import { useUserStoreWithOut } from '@/store/modules/user';
  import { loginApi, registerApi, registerCodeApi, registerCheckApi } from '@/api/login/index';
  import PageUtil from '@/utils/pageUtil';
  const userStore = useUserStoreWithOut();

  const props = defineProps({
    isDialogVisible: {
      type: Boolean,
      default: false,
    },
    isRegister: {
      type: Boolean,
      default: false,
    },
  });

  const emit = defineEmits(['close']);
  const isVisible = computed({
    get() {
      return props.isDialogVisible;
    },
    set(v) {
      emit('close', v);
    },
  });

  const isLogin = ref(!userStore.getIsRegister); //是否登录
  // 登录数据
  const loginForm = reactive({
    username: '',
    password: '',
    code: '',
    isCode: false, //是否验证码登录
    agree: false, // 同意协议
  });

  //注册表单数据
  const registerForm = reactive({
    email: '',
    code: '',
    password: '',
    surePassword: '',
    uuid: '',
    userRole: 'company',
    agree: false, // 同意协议
  });

  // 校验确认密码
  const validateSurePassword = (rule, value, callback) => {
    if (registerForm.password !== value) {
      callback(new Error('密码不一致'));
    } else {
      callback(); // 这里需要保证 callback 是一个函数并且被调用
    }
  };
  // 校验规则
  const rules = reactive({
    username: [
      { required: true, message: '请输入邮箱', trigger: 'blur' },
      { pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: '请输入正确的邮箱格式', trigger: 'blur' },
    ],
    email: [
      { required: true, message: '请输入邮箱', trigger: 'blur' },
      { pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: '请输入正确的邮箱格式', trigger: 'blur' },
    ],
    password: [
      { required: true, message: '请输入密码', trigger: 'blur' },
      {
        pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,18}$/,
        message: '密码必须是6~18位大小写字母和数字的组合',
        trigger: 'blur',
      },
    ],
    surePassword: [
      { required: true, message: '请再次输入密码', trigger: 'blur' },
      { validator: validateSurePassword, trigger: 'blur' },
    ],
    code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
  });

  const loginFormRef = ref();
  const registerFormRef = ref();
  const closeDialog = () => {
    userStore.setLoginVisible(false);
  };
  // 登录
  const login = async (isRegister: any = false) => {
    let obj = {
      username: loginForm.username,
      password: loginForm.password,
    };

    // 如果不是注册，进行表单验证
    if (!isRegister) {
      const valid = await loginFormRef.value.validate();
      if (!valid) {
        console.error('Validation failed');
        return;
      }
      if (!loginForm.agree) {
        return PageUtil.msgWarning('请先勾选同意服务协议，隐私政策。');
      }
    }

    // 发送登录请求
    try {
      const res = await loginApi(obj);
      setToken(res.access_token);
      userStore.setAccessToken(res.access_token);
      userStore.setUserInfo(res.data);
      await userStore.fetchUserInfo();
      emit('close');
    } catch (err) {
      PageUtil.msgError(err.data.message);
    }
  };

  const codeBtnText = ref('获取验证码');
  let timer;

  // 获取验证码
  const getCode = () => {
    if (codeBtnText.value !== '获取验证码') {
      return; // 如果按钮状态不是'获取验证码'，则直接返回，不执行后续逻辑
    }
    if (!registerForm.email) {
      PageUtil.msgWarning('请输入电子邮箱！');
      return; // 如果按钮状态不是'获取验证码'，则直接返回，不执行后续逻辑
    }
    // 增加一个正则校验邮箱
    const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!reg.test(registerForm.email)) {
      PageUtil.msgWarning('请输入正确的邮箱格式！');
      return; // 如果按钮状态不是'获取验证码'，则直接返回，不执行后续逻辑
    }

    let obj = {
      email: registerForm.email,
    };
    registerCheckApi(obj).then((res) => {
      if (res.data.enable) {
        registerCodeApi(obj).then((res) => {
          registerForm.code = res.data.code;
          registerForm.uuid = res.data.uuid;
          PageUtil.msgSuccess('验证码已发送，请前往邮箱查看。');
          timeout();
        });
      } else {
        PageUtil.msgError('邮箱重复');
      }
    });
  };
  const timeout = () => {
    codeBtnText.value = '60秒后重发';
    let time = 60;
    clearInterval(timer); // 清除之前的计时器
    timer = setInterval(() => {
      time--;
      codeBtnText.value = time + '秒后重发';
      if (time === 0) {
        clearInterval(timer);
        codeBtnText.value = '获取验证码';
      }
    }, 1000);
  };
  // 注册
  const register = () => {
    registerFormRef.value.validate((valid) => {
      if (valid) {
        if (!registerForm.agree) {
          return PageUtil.msgWarning('请先勾选同意服务协议，隐私政策。');
        }
        registerApi(registerForm).then((res) => {
          loginForm.username = registerForm.email;
          loginForm.password = registerForm.password;
          PageUtil.msgSuccess(res.msg);
          login(true);
        });
      }
    });
  };
</script>

<style scoped lang="scss">
  ::v-deep .el-dialog__body {
    padding: 20px 40px;
  }
  .block {
    padding: 0 20px;
  }
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 40px;
  }
  .btn {
    width: 370px;
    height: 40px;
    border-radius: 4px;
    margin-bottom: 20px;
  }
</style>
