// axios配置  可自行根据项目进行更改，只需更改该文件即可，其他文件可以不动

import type { AxiosResponse } from 'axios';
import type { RequestOptions, Result } from '#/axios';
import type { AxiosTransform, CreateAxiosOptions } from './axiosTransform';
import { CAxios } from './Axios';
import { RequestEnum, ResultEnum, ContentTypeEnum } from '@/enums/httpEnum';
import { isString } from '@/utils/is';
import { getToken, removeToken } from '@/utils/auth';
import { setObjToUrlParams, deepMerge } from '@/utils';
import { joinTimestamp, formatRequestDate } from './helper';
import PageUtil from '@/utils/pageUtil';
import { isDevMode } from '@/utils/env';
import { clearStorePersist } from '@/store';
import I18n from '@/i18n'; //国际化

const t = I18n.global.t;
const urlPrefix = import.meta.env.VITE_GLOB_API_URL;

/**
 * @description: 数据处理，方便区分多种处理方式
 */
const transform: AxiosTransform = {
  /**
   * @description: 处理请求数据。如果数据不是预期格式，可直接抛出错误
   */
  transformRequestHook: (res: AxiosResponse<Result>, options: RequestOptions) => {
    // const { t } = useI18n();
    const { isTransformResponse, isReturnNativeResponse } = options;
    // 是否返回原生响应头 比如：需要获取响应头时使用该属性
    if (isReturnNativeResponse) {
      return res;
    }
    // 不进行任何处理，直接返回
    // 用于页面代码可能需要直接获取code，data，message这些信息时开启
    if (!isTransformResponse) {
      return res.data;
    }
    // 错误的时候返回

    const { data } = res;
    if (!data) {
      // return '[HTTP] Request has no return value';
      throw new Error(t('http.noData'));
    }
    //  这里 code，result，message为 后台统一的字段，需要在 types.ts内修改为项目自己的接口返回格式
    const code = data.code;
    const message = data.msg;

    // 这里逻辑可以根据项目进行修改
    const hasSuccess = data && Reflect.has(data, 'code') && code === ResultEnum.SUCCESS;
    if (hasSuccess) {
      return data;
    }

    // 在此处根据自己项目的实际情况对不同的code执行不同的操作
    // 如果不希望中断当前请求，请return数据，否则直接抛出异常即可
    let timeoutMsg = '';
    switch (code) {
      case ResultEnum.TIMEOUT:
        timeoutMsg = t('http.timeOut');
        break;
      default:
        if (message) {
          timeoutMsg = message;
        }
    }

    // 接口错误，在此统一处理
    if (options.errorMessageMode === 'modal') {
    } else if (options.errorMessageMode === 'message') {
    }

    throw new Error(timeoutMsg || t('http.connectOut'));
  },

  // 请求之前处理config
  beforeRequestHook: (config, options) => {
    const { apiUrl, joinPrefix, joinParamsToUrl, formatDate, joinTime = true, urlPrefix } = options;
    if (config.url!.substr(0, 7).toLowerCase() === 'http://' || config.url!.substr(0, 8).toLowerCase() === 'https://') {
    }
    if (joinPrefix) {
      config.url = `${urlPrefix}${config.url}`;
    }

    if (apiUrl && isString(apiUrl)) {
      config.url = `${apiUrl}${config.url}`;
    }
    const params = config.params || {};
    const data = config.data || false;
    formatDate && data && !isString(data) && formatRequestDate(data);
    if (config.method?.toUpperCase() === RequestEnum.GET) {
      if (!isString(params)) {
        // 给 get 请求加上时间戳参数，避免从缓存中拿数据。
        config.params = Object.assign(params || {}, joinTimestamp(joinTime, false));
      } else {
        // 兼容restful风格
        config.url = config.url + params + `${joinTimestamp(joinTime, true)}`;
        config.params = undefined;
      }
    } else {
      if (!isString(params)) {
        formatDate && formatRequestDate(params);
        if (Reflect.has(config, 'data') && config.data && Object.keys(config.data).length > 0) {
          config.data = data;
          config.params = params;
        } else {
          // 非GET请求如果没有提供data，则将params视为data
          config.data = params;
          config.params = undefined;
        }
        if (joinParamsToUrl) {
          config.url = setObjToUrlParams(config.url as string, Object.assign({}, config.params, config.data));
        }
      } else {
        // 兼容restful风格
        config.url = config.url + params;
        config.params = undefined;
      }
    }
    return config;
  },

  /**
   * 请求错误
   * @param e 返回错误
   */
  requestCatchHook: (e) => {
    const msg = e.message.toLowerCase();
    if (msg.indexOf('timeout') > -1) {
      PageUtil.msg(t('http.timeOut'), 'warning', 1000);
    }
    return e;
  },

  /**
   * @description: 请求拦截器处理
   */
  requestInterceptors: (config, options) => {
    // 请求之前处理config
    const token = getToken();
    if (token && (config as Recordable)?.requestOptions?.withToken !== false) {
      // jwt token
      // (config as Recordable).headers.Authorization = options.authenticationScheme
      //   ? `${options.authenticationScheme} ${token}`
      //   : token;
    }
    (config as Recordable).headers.lang = String(localStorage.getItem('locale'));
    return config;
  },

  /**
   * @description: 响应拦截器处理
   */
  responseInterceptors: (res: AxiosResponse<any>) => {
    const data = res.data ?? {};
    if (data.code !== 200) {
      if (isDevMode() && data.msg) {
        // 开发模式下，提示错误信息
        PageUtil.notification(data.msg, t('http.warning'), 'warning', 'bottom-left');
      }
      if (data.msg && data.msg.indexOf(t('http.stateOut')) !== -1) {
        clearStorePersist();
        removeToken();
      }
      if (data.code === 401) {
        // 清空token
        removeToken();
      }
    }
    if (String(localStorage.getItem('locale')) === 'en') {
      if (res.data.data && res.data.data.length > 0) {
        res.data.data.forEach((v) => {
          if (v.dictLabel && v.dictLabelEn) {
            v.dictLabel = v.dictLabelEn;
            // delete v.dictLabelEn;
          }
        });
      }
    }
    return res;
  },

  /**
   * @description: 响应错误处理
   */
  responseInterceptorsCatch: (error: any) => {
    const { code, message, config } = error || {};
    const errorMessageMode = config?.requestOptions?.errorMessageMode || 'none';
    const err: string = error?.toString?.() ?? '';
    let errMessage = '';

    try {
      if (code === 'ECONNABORTED' && message.indexOf('timeout') !== -1) {
        errMessage = t('http.connectOut');
      }
      if (err?.includes('Network Error')) {
        errMessage = t('http.networkError');
      }

      if (errMessage) {
        if (errorMessageMode === 'modal') {
        } else if (errorMessageMode === 'message') {
        }
        return Promise.reject(error);
      }
    } catch (error) {
      throw new Error(error as unknown as string);
    }

    return Promise.reject(error);
  },
};

function createAxios(opt?: Partial<CreateAxiosOptions>) {
  return new CAxios(
    deepMerge(
      {
        // See https://developer.mozilla.org/en-US/docs/Web/HTTP/Authentication#authentication_schemes
        // authentication schemes，e.g: Bearer
        authenticationScheme: 'Bearer',
        timeout: 60 * 1000,
        // 基础接口地址
        // baseURL: globSetting.apiUrl,

        headers: { 'Content-Type': ContentTypeEnum.JSON },
        // 如果是form-data格式
        // headers: { 'Content-Type': ContentTypeEnum.FORM_URLENCODED },
        // headers: { 'Content-Type': ContentTypeEnum.FORM_DATA },
        // 数据处理方式
        transform,
        // 配置项，下面的选项都可以在独立的接口请求中覆盖
        requestOptions: {
          // 默认将prefix 添加到url
          joinPrefix: true,
          // 是否返回原生响应头 比如：需要获取响应头时使用该属性
          isReturnNativeResponse: false,
          // 需要对返回数据进行处理
          isTransformResponse: true,
          // post请求的时候添加参数到url
          joinParamsToUrl: false,
          // 格式化提交参数时间
          formatDate: true,
          // 消息提示类型
          errorMessageMode: 'message', // 可设置为modal/message/none
          // 接口地址
          apiUrl: '',
          // 接口拼接地址
          urlPrefix: urlPrefix,
          //  是否加入时间戳
          joinTime: true,
          // 忽略重复请求
          ignoreCancelToken: true,
          // 是否携带token
          withToken: true,
        },
      },
      opt || {},
    ),
  );
}
export const defHttp = createAxios();
