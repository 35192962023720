/**
 * @Created by why
 * @Created at 2024/02/22 9:52
 * @Modify By
 * @Modify at
 * @description 登录注册接口
 */

import { defHttp } from '@/http';

/**
 * 用户信息
 * @param
 * @returns
 */
export function getUserInfoApi() {
  return defHttp.get({
    url: '/user',
  });
}
/**
 * 用户详情信息
 * @param
 * @returns
 */
export function getUserInfoDetailApi() {
  return defHttp.get({
    url: '/user/myDetail',
  });
}

/**
 * 用户详情信息
 * @param
 * @returns
 */
export function getUserDetailApi(id) {
  return defHttp.get({
    url: `/user/detail/${id}`,
  });
}

/**
 * 用户编辑
 * @param
 * @returns
 */
export function editUserApi(params) {
  return defHttp.put({
    url: '/user',
    params
  });
}
/**
 * 用户身份认证
 * @param
 * @returns
 */
export function identityApi(params) {
  return defHttp.post({
    url: '/user/realName',
    params
  });
}
/**
 * 用户详情保存
 * @param
 * @returns
 */
export function saveUserApi(params) {
  return defHttp.post({
    url: '/user/saveUserBlurb',
    params
  });
}
// 修改密码
export function updatePwdApi(data) {
  return defHttp.put({
    url: '/user/pwd/change',
    data,
  });
}
