import type { App } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

const store = createPinia();
// 持久化存储默认使用session
store.use(piniaPluginPersistedstate);

export function setupStore(app: App<Element>) {
  app.use(store);
}

export { store };

// 清空pinia的持久化存储，清空session
export function clearStorePersist() {
  const itemsToRemove = [
    'CoProjectUser',
  ];
  itemsToRemove.forEach((item) => {
    if (sessionStorage.getItem(item)) {
      sessionStorage.removeItem(item);
    }
  });
}
