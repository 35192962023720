<!--
-@Created by nkq
-@Created at 2024/01/30 10:56
-@Modify By
-@Modify at
-@description: 页面布局
-->

<template>
  <div class="main-container">
    <div class="main-container--header">
      <Header />
    </div>

    <div v-if="!chouseRouteMath('bg') && !chouseRouteMath('setting')" class="main-container--content">
      <router-view />
    </div>
    <div v-else class="main-container--content-bg">
      <router-view />
    </div>
    <div class="main-container--footer" v-if="!chouseRouteMath('bg') && !chouseRouteMath('setting')">
      <Footer />
    </div>
  </div>

  <!-- 登录注册弹框 -->
  <login-or-register-dialog v-if="addVisible" :is-dialog-visible="addVisible" @close="close" />
</template>

<script setup lang="ts">
  import Header from './components/Header/index.vue';
  import PageUtil from '@/utils/pageUtil';
  import Footer from './components/Footer/index.vue';
  import LoginOrRegisterDialog from '@/components/login/loginOrRegister.vue';
  import { onMounted, ref, watch } from 'vue';
  import { useUserStoreWithOut } from '@/store/modules/user';
  import { useRoute } from 'vue-router';

  const route = useRoute();
  const userStore = useUserStoreWithOut();

  const addVisible = ref(false);
  // 判断路由名字是否在路里面
  const chouseRouteMath = (routeName: string): boolean => {
    return PageUtil.routeNameInPath(route.path, routeName);
  };
  watch(
    () => userStore.getLoginVisible,
    (n) => {
      addVisible.value = n;
    },
  );
  onMounted(() => {
    if (userStore.getLoginVisible) {
      addVisible.value = true;
    }
  });
  // 关闭登录弹框
  const close = () => {
    addVisible.value = false;
    userStore.setLoginVisible(false);
    userStore.setIsRegister(false);
  };
</script>

<style scoped lang="scss">
  .main-container {
    position: relative;
  }
  .main-container--header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
  }
  .main-container--content {
    margin-top: 64px;
    background-color: #fff;
  }
  .main-container--content-bg {
    margin-top: 64px;
  }
  .main-container--footer {
    background-color: #151b26;
  }
</style>
