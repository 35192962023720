<!--
-@Created by nkq
-@Created at 2024/02/18 16:52
-@Modify By 
-@Modify at 
-@description: 网站页脚
-->
<template>
  <div class="footer-container">
    <div class="top-block">
      <div>
        <div class="logo-block">
          <a href="/" @click.prevent>EZCMMI</a>
          <div class="title">EZCMMI评估平台</div>
        </div>
        <div class="language"><svg-icon name="language" :size="18" />汉语</div>
        <!-- <div class="language"><svg-icon name="language" :size="18" />英语</div> -->
      </div>
      <div class="service-block">
        <div class="service">
          <div class="text-weight">关于我们</div>
          <div class="text-gray">公司简介</div>
          <div class="text-gray">平台公告</div>
        </div>
        <div class="service">
          <div class="text-weight">交易保障</div>
          <div class="text-gray">担保交易</div>
          <div class="text-gray">支付方式</div>
        </div>
        <div class="service">
          <div class="text-weight">帮助中心</div>
          <div class="text-gray">会员中心</div>
        </div>
      </div>

      <div>
        <div class="text-weight">服务热线</div>
        <div class="phone">0311-86210070</div>
      </div>
      <div>
        <div class="text-weight">关注我们</div>
        <div>
          <img class="img-box" src="@/assets/images/wechat.png" alt="微信" />
          <img class="img-box" src="@/assets/images/weibo.png" alt="微博" />
          <img src="@/assets/images/qiwei.png" alt="企业微信" />
        </div>
      </div>
    </div>
    <div class="middle-block"></div>
    <div class="bottom-block">
      <div>Copyright © 2019-2024</div>
      <div>河北众包科技有限公司 版权所有</div>
      <div>冀ICP备20009230号</div>
      <div>冀公网安备 13010202002628号</div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
  .footer-container {
    width: 1480px;
    margin: 0 auto;
    box-sizing: border-box;
    color: #ffffff;

    .text-weight {
      font-weight: 600;
      color: #ffffff;
      margin-bottom: 11px;
    }
    .text-gray {
      color: #ebebeb;
      margin-bottom: 13px;
    }
    .top-block {
      box-sizing: border-box;

      height: 139px;
      padding: 20px 0;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      background-color: #151b26;

      .logo-block {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 13px;
        a {
          display: block;
          width: 40px;
          height: 25px;
          background: url('@/assets/images/logo.png') no-repeat;
          font-size: 0;
          text-indent: -9999px;
          background-size: cover;
        }
        .title {
          font-size: 18px;
          font-weight: 600;
          line-height: 25px;
          margin-left: 9px;
        }
      }
      .language {
        margin-left: 45px;
        margin-bottom: 5px;
        color: #ebebeb;
      }
      .service-block {
        display: flex;
        justify-content: space-between;
        .service {
          margin-right: 80px;
        }
      }
      .phone {
        font-size: 20px;
        margin-top: 18px;
      }
      .img-box {
        margin-right: 20px;
        margin-top: 14px;
      }
    }
    .middle-block {
      width: 100%;
      height: 1px;
      background: #3a4049;
    }

    .bottom-block {
      box-sizing: border-box;
      height: 60px;
      padding: 0 180px;
      line-height: 60px;
      color: #ebebeb;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      background-color: #151b26;
    }
  }
</style>
