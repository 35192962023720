import { RouteRecordRaw } from 'vue-router';

/**
 * 后台路由
 */
export const adminSystemRouter: RouteRecordRaw[] = [
  {
    path: '/setting',
    name: 'Setting',
    meta: {
      title: '系统设置',
    },
    component: () => import('@/views/adminSystem/index.vue'),
    redirect: { name: 'userManagement' },
    children: [

      {
        path: 'newsManagement',
        name: 'newsManagement',
        meta: {
          title: '动态管理',
        },
        component: () => import('@/views/adminSystem/newsManagement/index.vue'),
      },

      {
        path: 'communityManagement',
        name: 'communityManagement',
        meta: {
          title: '社区管理',
        },
        component: () => import('@/views/adminSystem/communityManagement/index.vue'),
      },
      {
        path: 'knowledgeBase',
        name: 'knowledgeBase',
        meta: {
          title: '知识库管理',
        },
        component: () => import('@/views/adminSystem/knowledgeBase/index.vue'),
      },
      {
        path: 'userManagement',
        name: 'userManagement',
        meta: {
          title: '用户管理',
        },
        component: () => import('@/views/adminSystem/userManagement/index.vue'),
      },
      {
        path: 'userAuditManagement',
        name: 'userAuditManagement',
        meta: {
          title: '用户审核',
        },
        component: () => import('@/views/adminSystem/userAuditManagement/index.vue'),
      },
      {
        path: 'blackManagement',
        name: 'blackManagement',
        meta: {
          title: '黑名单',
        },
        component: () => import('@/views/adminSystem/blackManagement/index.vue'),
      },
    ],
  },
];
