<!--
-@Created by nkq
-@Created at 2024/02/05 10:32
-@Modify By
-@Modify at
-@description: 个人中心的下拉菜单
-->

<template>
  <avatar size="30" :src="profilePhoto" class="cursor-pointer mr-1" />
  <el-dropdown :hide-on-click="false">
    <div class="cursor-pointer">
      <el-icon><CaretBottom /></el-icon>
      <span>{{ nickName || '' }}</span>
    </div>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item @click.prevent="goToBg">个人中心</el-dropdown-item>
        <el-dropdown-item @click="logout">退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script setup lang="ts">
  import { computed } from 'vue';
  import { CaretBottom } from '@element-plus/icons-vue';
  import { useUserStoreWithOut } from '@/store/modules/user';
  import { logoutApi } from '@/api/login/index';
  import { removeToken } from '@/utils/auth';
  import { clearStorePersist } from '@/store';
  import { useRouter } from 'vue-router';
  const router = useRouter();
  const userStore = useUserStoreWithOut();

  const nickName = computed(() => userStore.getUserInfo?.nickname); //昵称
  const profilePhoto = computed(() => userStore.getUserInfo?.avatar); // 头像
  // 退出登录
  const logout = () => {
    logoutApi().then(() => {
      removeToken();
      userStore.setUserInfo({});
      userStore.setAccessToken('');
      router.push({ name: 'home' });
      clearStorePersist();
    });
  };

  // 定义导航到后台页面的方法
  const goToBg = () => {
    router.push({ name: 'bg' });
  };
</script>

<style scoped lang="scss">
  :deep(.el-tooltip__trigger:focus-visible) {
    outline: unset;
  }
</style>
