import { RouteRecordRaw } from 'vue-router';

/**
 * 后台路由
 */
export const bgRouter: RouteRecordRaw[] = [
  {
    path: '/bg',
    name: 'bg',
    meta: {
      title: '后台',
    },
    component: () => import('@/views/background/index.vue'),
    redirect: { name: 'myWork' },
    children: [
      {
        path: 'myWork',
        name: 'myWork',
        meta: {
          title: '我的工作',
        },
        component: () => import('@/views/background/myWork/index.vue'),
      },
      {
        path: 'myPublishDetail',
        name: 'myPublishDetail',
        meta: {
          title: '我发布的详情',
        },
        component: () => import('@/views/background/myWork/publishDetail.vue'),
      },
      {
        path: 'clientDetail/:id?',
        name: 'clientDetail',
        meta: {
          title: '投标详情',
        },
        component: () => import('@/views/background/myWork/components/clientList.vue'),
      },
      {
        path: 'assessPlan',
        name: 'assessPlan',
        meta: {
          title: '评估计划',
        },
        component: () => import('@/views/background/assessPlan/index.vue'),
      },
      {
        path: 'planDetail',
        name: 'planDetail',
        meta: {
          title: '评估计划详情',
        },
        component: () => import('@/views/background/assessPlan/planDetail.vue'),
      },
      {
        path: 'document',
        name: 'document',
        meta: {
          title: '文档管理',
        },
        component: () => import('@/views/background/document/index.vue'),
      },
      // {
      //   path: 'assessProject',
      //   name: 'assessProject',
      //   meta: {
      //     title: '评估项目',
      //   },
      //   component: () => import('@/views/background/assessProject/index.vue'),
      // },
      // {
      //   path: 'myOrders',
      //   name: 'myOrders',
      //   meta: {
      //     title: '我的订单',
      //   },
      //   component: () => import('@/views/background/myOrders/index.vue'),
      // },
      {
        path: 'myProfile',
        name: 'myProfile',
        meta: {
          title: '我的信息',
        },
        component: () => import('@/views/background/myInfo/index.vue'),
      },
    ],
  },
];
