/**
 * @Created by lyj
 * @Created at 2024/01/29 17:19
 * @Modify By
 * @Modify at
 * @description: 获取焦点指令。使用方法: 在input上使用 v-focus。注意事项: 一个页面只用一次此指令，后边的会把前边的覆盖掉。
 **/

import { App, Directive } from 'vue';

const focusDirective: Directive = {
  mounted(el: any) {
    el.querySelector('input').focus();
  },
};

export function setupFocusDirective(app: App) {
  app.directive('focus', focusDirective);
}

export default setupFocusDirective;
