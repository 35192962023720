import { RouteRecordRaw } from 'vue-router';

/**
 * staticRouter (静态路由)
 */
export const staticRouter: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'home',
    meta: { title: '首页' },
    component: () => import('@/views/home/index.vue'),
  },
  {
    path: '/appraiser',
    name: 'appraiser',
    meta: { title: '找评估师' },
    component: () => import('@/views/appraiser/index.vue'),
  },
  {
    path: '/appraiser/detail',
    name: 'appraiserDetail',
    meta: { title: '评估师详情' },
    component: () => import('@/views/appraiser/appraiserDetail.vue'),
  },
  {
    path: '/project',
    name: 'project',
    meta: { title: '找项目' },
    component: () => import('@/views/project/index.vue'),
  },
  {
    path: '/project/detail',
    name: 'projectDetail',
    meta: { title: '项目详情' },
    component: () => import('@/views/project/projectDetail.vue'),
  },
  {
    path: '/translator',
    name: 'translator',
    meta: { title: '找翻译' },
    component: () => import('@/views/translator/index.vue'),
  },
  {
    path: '/translator/detail',
    name: 'translatorDetail',
    meta: { title: '翻译详情' },
    component: () => import('@/views/appraiser/appraiserDetail.vue'),
  },
  {
    path: '/trends',
    name: 'trends',
    meta: { title: 'cmmi动态' },
    component: () => import('@/views/cmmiNews/index.vue'),
  },
  {
    path: '/knowledge',
    name: 'knowledge',
    meta: { title: '知识库' },
    component: () => import('@/views/knowledge/index.vue'),
  },
  {
    path: '/cmmiNews',
    name: 'cmmiNews',
    meta: { title: 'cmmi动态详情' },
    component: () => import('@/views/cmmiNews/cmmiDetail.vue'),
  },
  {
    path: '/community',
    component: () => import('@/views/community/index.vue'),
    name: 'community',
    // meta: { title: '社区' },
    redirect: { name: 'sectionList' }, // 默认到case页面
    children: [
      {
        path: 'sectionList',
        component: () => import('@/views/community/sectionList.vue'),
        name: 'sectionList',
        meta: { title: '社区' },
        children: [
          {
            path: 'sectionDetail',
            component: () => import('@/views/community/sectionDetail.vue'),
            name: 'sectionDetail',
            meta: { title: ' ' },
            children: [
              {
                // path: 'themeDetail/:themeId',
                path: 'themeDetail',
                component: () => import('@/views/community/themeDetail.vue'),
                name: 'themeDetail',
                meta: { title: ' ' },
                children: [
                  {
                    path: 'themeEdit',
                    component: () => import('@/views/community/themeEdit.vue'),
                    name: 'themeEdit',
                    meta: { title: '修改' },
                  },
                ],
              },
              {
                path: 'themeAdd',
                component: () => import('@/views/community/themeAdd.vue'),
                name: 'themeAdd',
                meta: { title: '发帖' },
              },
            ],
          },
        ],
      },
      // {
      //   path: '/community/masterHome',
      //   component: () => import('@/views/findMaster/masterHomePage'),
      //   name: 'masterHomePage',
      //   meta: { title: '达人首页' }
      // },
    ],
  },
];

/**
 * errorRouter (错误页面路由)
 */
export const errorRouter = [
  {
    path: '/404',
    name: 'NotFound',
    meta: { title: '404' },
    hidden: true,
    component: () => import('@/views/error/404.vue'),
  },
  // Resolve refresh page, route warnings
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'NotFound' },
    hidden: true,
  },
];
