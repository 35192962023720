/**
 * Configure and register global directives
 */
import { App } from 'vue';
import { setupFocusDirective } from './focus';
import { setupWaterMarkerDirective } from './waterMarker';
import { setupDebounceDirective } from './debounce';
import { setupThrottleDirective } from './throttle';
import { setupMiniHeightDirective } from './miniHeight';
import { setupCocodeLoadingDirective } from './cocodeLoading';

export function setupGlobDirectives(app: App) {
  setupFocusDirective(app);
  setupWaterMarkerDirective(app);
  setupDebounceDirective(app);
  setupThrottleDirective(app);
  setupMiniHeightDirective(app);
  setupCocodeLoadingDirective(app);
}
