export default {
  http: {
    warning: 'Warning',
    noData: 'No data returned',
    timeOut: 'Request timed out',
    connectOut: 'Connection timed out',
    stateOut: 'Login status has expired',
    networkError: 'Network Error',
  },
  utils: {
    pageUtil: {
      warning: 'Warning',
      cancel: 'Cancel',
      sure: 'Confirm',
    },
  },
}