<!--
-@Created by nkq
-@Created at 2024/02/05 09:56
-@Modify By 
-@Modify at 
-@description: 用户头像组件
-->
<template>
  <el-avatar :size="imgSize" :src="imgSrc" @error="errorHandler">
    <img src="~@/assets/avatar/default-avatar.png" />
  </el-avatar>
</template>

<script lang="ts">
  import { computed, defineComponent, reactive, toRefs } from 'vue';

  export default defineComponent({
    props: {
      size: {
        type: [String, Number],
        required: true,
        default: '',
      },
      src: {
        type: String,
        required: true,
        default: '',
      },
    },
    setup(props) {
      const api = import.meta.env.VITE_GLOB_API_URL;
      const baseApi = String(api);
      const errorHandler = () => true;

      const reactiveData = reactive({
        imgSrc: computed(() => {
          if (
            props.src &&
            (props.src.startsWith('https://') || props.src.startsWith('http://') || props.src.startsWith(baseApi))
          ) {
            return props.src;
          }
          return `${baseApi}${props.src}`;
        }),
        imgSize: computed(() => {
          const m = String(props.size).match(/([0-9]*)/);
          return m !== null ? Number(m[0]) : 30;
        }),
      });
      return {
        errorHandler,
        ...toRefs(reactiveData),
      };
    },
  });
</script>

<style scoped lang="scss"></style>
